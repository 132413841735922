import styles from './Lever.module.scss';

import { LEVER } from '@/utilities/constants/Lever';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocale } from '@/utilities/contexts/Locale';
import apiLever from '@/utilities/api/Lever';
import Button from '@/components/atoms/Button';
import Card from '@/components/molecules/Card';
import ChevronDown from '@/icons/maggie/ChevronDown';
import Column from '@/components/atoms/Column';
import LeverTypes, { LeverPosting } from '@/utilities/types/Lever';
import Row from '@/components/atoms/Row';
import Typography from '@/components/atoms/Typography';
import useSWR from 'swr';
import RotateRight from '@/icons/maggie/RotateRight';

const Lever = (props: LeverTypes) => {
  const locale = useLocale();
  const [currentPage, setCurrentPage] = useState(1);
  const { data } = useSWR<LeverPosting[]>(
    props.url.replaceAll('amp;', ''),
    apiLever,
  );

  const [jobTypeFilterOptions, setJobTypeFilterOptions] = useState<string[]>(
    [],
  );
  const [locationFilterOptions, setLocationFilterOptions] = useState<string[]>(
    [],
  );

  const [categoryFilter, setCategoryFilter] = useState<string | undefined>(
    undefined,
  );
  const [locationFilter, setLocationFilter] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    const categories: Record<string, boolean> = {};
    const locations: Record<string, boolean> = {};
    data.forEach((posting) => {
      categories[posting?.categories?.team] = true;
      locations[posting?.categories?.location] = true;
    });
    setJobTypeFilterOptions(Object.keys(categories).sort());
    setLocationFilterOptions(Object.keys(locations).sort());
  }, [data]);

  const filteredData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.filter((posting) => {
      if (categoryFilter && posting.categories.team !== categoryFilter) {
        return false;
      }
      return !(
        locationFilter && posting.categories.location !== locationFilter
      );
    });
  }, [categoryFilter, data, locationFilter]);

  const displayData: LeverPosting[] = useMemo(() => {
    if (props.maxJobs) {
      return filteredData.slice(
        (currentPage - 1) * props.maxJobs,
        currentPage * props.maxJobs,
      );
    }
    return filteredData;
  }, [filteredData, currentPage, props.maxJobs]);

  const [filterOpen, setFilterOpen] = useState<'categories' | 'locations' | ''>(
    '',
  );
  const buttonRefCategories = useRef<HTMLDivElement>(null);
  const buttonRefLocations = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        buttonRefCategories.current &&
        !buttonRefCategories.current?.contains(event.target as Node)
      ) {
        setFilterOpen((prev) => {
          if (prev === 'categories') {
            return '';
          }
          return prev;
        });
      }
      if (
        buttonRefLocations.current &&
        !buttonRefLocations.current?.contains(event.target as Node)
      ) {
        setFilterOpen((prev) => {
          if (prev === 'locations') {
            return '';
          }
          return prev;
        });
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderCards = () => (
    <Row xs={2}>
      {displayData &&
        displayData.map((posting) => (
          <Column
            key={posting.id}
            xs={12}
            md={6}
            lg={4}
            className={styles.card}
          >
            <Card
              card={[
                {
                  chips: [
                    {
                      color: 'charcoal-charlie',
                      chip: posting?.categories?.team,
                    },
                    {
                      color: 'charcoal-charlie',
                      chip: posting?.categories?.location,
                    },
                    {
                      color: 'charcoal-charlie',
                      chip: posting?.categories?.commitment,
                    },
                  ],
                },
                { title: posting.text },
                { description: posting.descriptionPlain },
                {
                  links: [
                    {
                      link: {
                        title: LEVER.learnMore[locale],
                        url: posting.hostedUrl,
                        target: true,
                      },
                      style: 'link',
                    },
                  ],
                },
              ]}
              customise={{
                alignment: 'left',
                link: {
                  target: true,
                  url: posting.hostedUrl,
                  title: posting.text,
                },
              }}
              heading={'h3'}
              stretch={true}
            />
          </Column>
        ))}
    </Row>
  );

  const renderHeader = () => (
    <div className={styles.filter}>
      <Row>
        <div className={styles.filterWrapper}>
          <Button
            className={styles.resetButton}
            after={<RotateRight />}
            size="medium"
            style="tertiary"
            onClick={() => {
              setLocationFilter(undefined);
              setCategoryFilter(undefined);
            }}
          >
            {LEVER.reset[locale]}
          </Button>
          <div ref={buttonRefCategories} className={styles.filterItem}>
            <Typography
              tag="span"
              xs={3}
              weight={'medium'}
              family="noto-sans"
              className={styles.filterLabel}
            >
              {LEVER.jobSearch[locale]}
            </Typography>
            <Button
              type="button"
              after={<ChevronDown width={16} height={16} />}
              size="medium"
              style="secondaryToggle"
              onClick={() =>
                setFilterOpen(filterOpen === 'categories' ? '' : 'categories')
              }
              className={`${filterOpen === 'categories' ? 'isActive' : ''}`}
            >
              {categoryFilter ? categoryFilter : LEVER.all[locale]}
              <ul>
                <li
                  key={'all'}
                  onClick={() => {
                    setCategoryFilter(undefined);
                  }}
                >
                  <span>All</span>
                </li>
                {jobTypeFilterOptions.map((option) => (
                  <li
                    key={option}
                    onClick={() => {
                      setCategoryFilter(option);
                    }}
                  >
                    <span>{option}</span>
                  </li>
                ))}
              </ul>
            </Button>
          </div>
          <div className={styles.divider} />
          <div ref={buttonRefLocations} className={styles.filterItem}>
            <Typography
              tag="span"
              xs={3}
              family="noto-sans"
              weight={'medium'}
              className={styles.filterLabel}
            >
              Search by location
            </Typography>
            <Button
              type="button"
              after={<ChevronDown width={16} height={16} />}
              size="medium"
              style="secondaryToggle"
              onClick={() =>
                setFilterOpen(filterOpen === 'locations' ? '' : 'locations')
              }
              className={`${filterOpen === 'locations' ? 'isActive' : ''}`}
            >
              {locationFilter ? locationFilter : LEVER.all[locale]}
              <ul>
                <li
                  key={'all'}
                  onClick={() => {
                    setLocationFilter(undefined);
                  }}
                >
                  <span>All</span>
                </li>
                {locationFilterOptions.map((option) => (
                  <li
                    key={option}
                    onClick={() => {
                      setLocationFilter(option);
                    }}
                  >
                    <span>{option}</span>
                  </li>
                ))}
              </ul>
            </Button>
          </div>
        </div>
      </Row>
    </div>
  );

  const renderEmpty = () => {
    return (
      <div className={styles.noResults}>
        <Typography xs={3} family="noto-sans" tag="span">
          {LEVER.notFound[locale]}
        </Typography>
      </div>
    );
  };

  const renderFooter = () => {
    const pages = Math.round(filteredData.length / props.maxJobs);
    return (
      <Row>
        <Column xs={12} className={styles.pagination}>
          {pages > 1 && (
            <div className={styles.paginationButtons}>
              {Array.from({ length: pages }, (_, i) => i + 1).map(
                (page, index) => (
                  <Button
                    key={index}
                    size="medium"
                    style={currentPage === page ? 'primary' : 'secondary'}
                    onClick={() => {
                      setCurrentPage(page);
                    }}
                  >
                    {page}
                  </Button>
                ),
              )}
            </div>
          )}
        </Column>
      </Row>
    );
  };

  return (
    <div className={styles.root}>
      {props.allJobs && renderHeader()}
      {renderCards()}
      {props.allJobs && displayData.length === 0 && renderEmpty()}
      {props.allJobs && renderFooter()}
    </div>
  );
};

export default Lever;
